import api from "@/api/index";

export default {
  //PUBLIC
  updateKYC(credentials) {
    return api().post("kyc/update", credentials);
  },

  data(credentials) {
    return api().get("kyc/data", credentials);
  },

  byUser(credentials) {
    return api().post("kyc/by_user", credentials);
  },
};
