<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="flex flex-col items-center justify-start w-full text-yellow-50 p-3 mb-28"
      >
        <div class="py-1 max-w-5xl w-full flex-none">
          <div class="p-4">
            <div>
              <a
                v-on:click="$router.go(-1)"
                class="flex items-center space-x-2 text-center cursor-pointer"
                ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
              >
            </div>
          </div>
        </div>

        <div class="py-4 space-y-6 md:py-8 lg:py-12 max-w-2xl w-full -mt-5">
          <h2
            class="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-800"
          >
            Take a photo
          </h2>

          <img class="h-60 inline mr-2 mt-3" src="@/assets/img/kyc/kyc.jpeg" />
          <div class="p-1">
            <div>
              <div class="">
                <div class="w-full">
                  <div v-if="dataKYC">remark: {{ dataKYC.remark }}</div>
                  <br />

                  <div
                    class="flex border-t border-l border-blue-500 shadow-md border-opacity-25 py-1 px-3 bg-blue-800 rounded-lg bg-opacity-20 mb-2 items-center"
                  >
                    <input
                      type="file"
                      @change="uploadImage($event)"
                      accept="image/*"
                      id="recfile"
                      name="recfile"
                    />
                  </div>

                  <router-link
                    v-bind:to="'/addphone/' + address"
                    class="flex mt-10 border-t border-l border-blue-500 shadow-md border-opacity-25 py-1 px-3 bg-gradient-to-r from-green-600 to-green-800 rounded-lg bg-opacity-20 mb-2 items-center text-center"
                    @click="c()"
                  >
                    <div class="flex-grow cursor-pointer">
                      <h3
                        class="p-3 text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-gray-200"
                      >
                        Continue
                      </h3>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div></div>
        <footer>
          <StickFooter />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import { mapGetters, mapActions } from "vuex";

import kyc from "@/api/kyc";

import axios from "axios";

import { API_URL } from "../../config";

export default {
  data() {
    return {
      address: this.$route.params.address,
      event: null,
      dataKYC: {},
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
  },
  methods: {
    computed: mapGetters(["getBalance", "getCurrency", "getUser"]),

    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },
    async uploadImage(event) {
      this.event = event;
    },

    c(event) {
      const URL = `${API_URL}/kyc/create/img`;
      let data = new FormData();
      data.append("file", this.event.target.files[0]);
      data.append("address", this.address);
      axios.post(URL, data).then((response) => {
        console.log("image upload response > ", response);
      });
    },
    async getDataKYC() {
      await kyc
        .byUser({
          address: this.address,
        })
        .then((res) => {
          this.dataKYC = res.data.data;
        })
        .catch((error) => {});
    },
  },
  created() {
    this.getDataKYC();
  },
};
</script>
