<template>
  <section
    id="bottom-navigation"
    class="block inset-x-0 fixed bottom-0 z-999 bg-gray-900 bg-opacity-75 backdrop-filter backdrop-blur-md shadow pb-2 text-gray-300"
  >
    <div id="tabs" class="flex justify-between mx-auto max-w-4xl">
      <router-link
        to="/"
        class="w-full opacity-50 hover:opacity-100 focus:opacity-100 justify-center inline-block text-center pt-2 pb-1"
      >
        <i class="fad fa-home mr-1"></i>
        <span class="tab tab-home block text-xs">HOME</span>
      </router-link>

      <router-link
        to="/maps"
        class="w-full opacity-50 hover:opacity-100 focus:opacity-100 justify-center inline-block text-center pt-2 pb-1"
      >
        <i class="fas fa-chess"></i>
        <span class="tab tab-explore block text-xs">Map</span>
      </router-link>

      <router-link
        to="/refer"
        class="w-full opacity-50 hover:opacity-100 focus:opacity-100 justify-center inline-block text-center pt-2 pb-1"
      >
        <i class="fad fa-user-astronaut"></i>
        <span class="tab tab-account block text-xs">Rederral</span>
      </router-link>
      <router-link
        to="/funds"
        class="w-full opacity-50 hover:opacity-100 focus:opacity-100 justify-center inline-block text-center pt-2 pb-1"
      >
        <i class="fad fa-wallet"></i>
        <span class="tab tab-account block text-xs">FUNDS</span>
      </router-link>
    </div>
  </section>
</template>

<style scoped>
.router-link-exact-active {
  border-top-width: 2px;
  color: #fff;
  border-top: solid;
  padding-top: 5px;
  opacity: 1;
}
</style>
